<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { i18n } from "vue-lang-router";

export default {
    name: 'picture',

    data() {
        return {
            name: '',
            email: '',
        };
    },

    created() {
        document.title = "Om Tempus Bildbyrås bilder";
    },

    computed: {
        ...mapGetters(['isLoading']),

    },

    methods: {
        send() {
            if (this.name == '' || this.email == '') {
                alert('Please complete all fields');
                return;
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.email)) {
                alert('Email field must have email form');
                return;
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/newsletter/save`,
                { name: this.name, email: this.email }).then(() => {
                    this.name = "";
                    this.email = "";
                    alert('Saved');
                });
        },
    },

    components: {},
};
</script>

<template>
    <div class="illustration-info-container">
        <div class="illustration-info">
            <div class="content">
                <div class="header p-4">
                    <h1 class="text-3xl">Om bilderna: Bokillustrationer under femhundra år</h1>
                </div>
                <br>
                <div class="content-paragraph">
                    Först på 1890-talet började man på fotomekanisk väg kunna göra exakta avbildningar av fotografier eller teckningar i tryck. I dag har vi dessutom den digitala tekniken som underlättar ytterligare. Fram till 1890-talet var man däremot tvungen att använda andra metoder för att trycka bilder. Man var hänvisad till att trycka bilder antingen med en gravyr i metall (kopparstick eller stålstick), med sten (litografi, stentryck) eller med en gravyr i trä (xylografi, trägravyr). Den i särklass vanligaste – och ekonomiskt sett konkurrenskraftigaste – var trägravyren. Det är också trägravyrerna som är den vanligaste illustrationstypen i Tempus Bildbyrås samlingar. Här tecknas översiktligt bokillustrationernas historia, med särskilt fokus på trägravyrer.
                    <br/><br/>
                    En trägravyr är en bild som har tryckts genom att papper har pressats mot en träplatta med ett utskuret motiv. Motivet skapas genom att en gravör med hjälp av knivar, fina mejslar och sticklar avlägsnar trämaterial från en träplatta, så att de upphöjda partierna av träplattan utgör motivet. Resultatet kallas träsnitt, trägravyr eller xylografi.
                </div>
                <div class="content-paragraph borderedImage">
                    <img src="@/assets/wood-block.jpg" alt="Bilden har skurits ut i ett stycke päronträ och till höger ses det färdiga resultatet i en bok om botanik. Träsnittet utfördes av Wolfgang Meyerpeck på 1560-talet. WELLCOME COLLECTION"/>
                    <p>Bilden har skurits ut i ett stycke päronträ och till höger ses det färdiga resultatet i en bok om botanik. Träsnittet utfördes av Wolfgang Meyerpeck på 1560-talet. WELLCOME COLLECTION</p>
                </div>
                <div class="content-paragraph">
                     <h2>Långa anor</h2>
                </div>
                <div class="content-paragraph  split-content">
                     <p class="leftSide">Träsnittskonsten har sitt ursprung i Kina för mer än tvåtusen år sedan. I Europa togs den i bruk i mitten av 1400-talet som illustrationsmetod för de böcker som man då började trycka. Först hade man tryckt så kallade blockböcker, där text och illustrationer hade skurits i samma träblock som sedan infärgades och trycktes på boksidorna. Snart började Johann Gutenberg och andra i stället arbeta med lösa bokstavstyper, men träblocken användes även fortsatt för att skapa bilderna. Dessa träsnitt skapades genom att bilden ritades med penna på träblocket, varpå de ytor som skulle förbli ofärgade skars bort med kniv, stickel och håljärn, så att de upphöjda partierna utgjorde motivet och användes för att färga ytor i det färdiga trycket. </p>
                      <div class="content-paragraph borderedImage">
                        <img style="border: 1px solid grey;" src="@/assets/astronomer.jpg" alt="Detta träsnitt av Albrecht Dürer föreställer en astronom." />
                        <p>Detta träsnitt av Albrecht Dürer föreställer en astronom.</p>
                      </div>
                </div>

                 <div class="content-paragraph">
                         <p>Under 1400- och 1500-talen användes så träsnitten i stor omfattning för illustration av böcker och en av den tidens mest framstående gravörer var Albrecht Dürer. Ett för svenskar känt bokverk är Olaus Magnus <i>Historia om de nordiska folken</i> (1555), som är rikt illustrerat med träsnitt.</p>
                         
                    </div>

                <div class="content-paragraph">
                    <p>Träsnittet fick snart konkurrens av kopparsticket och kom att bli den billigare och enklare illustrationsmetoden av de båda, använd främst i folkligare bokupplagor. Kopparsticken användes i de mer påkostade böckerna, exempelvis bokserien <i>Theatrum europaeum</i> som från 1635 till 1738 fortlöpande gavs ut och krönikerade samtidens politiska och militära händelser. Förläggare till detta verk var Matthäus Merian och dennes ättlingar. Merians förlag gav också ut <i>Topographia germaniae</i>, som med sina drygt 2 000 kopparstick dokumenterar städer, slott och borgar i framför allt dagens Tyskland. Detta bokverk inspirerade Erik Dahlbergh till att ge ut den svenska motsvarigheten <i>Suecia antiqua et hodierna</i>.</p>
                   
                </div>

                <div class="content-paragraph borderedImage">
                    <img src="@/assets/topographia-germaniae.jpg" alt="Ett uppslag ur bandet om Österrike i <i>Topographia germaniae</i>. Den praktfulla vyn visar staden Steyr i Österrike ur fågelperspektiv. UNIVERSITÄTS- UND LANDESBIBLIOTHEK DÜSSELDORF" />
                    <p>Ett uppslag ur bandet om Österrike i <i>Topographia germaniae</i>. Den praktfulla vyn visar staden Steyr i Österrike ur fågelperspektiv. UNIVERSITÄTS- UND LANDESBIBLIOTHEK DÜSSELDORF</p>
                </div>

                 <div class="content-paragraph">
                    <h2>Trägravyrernas renässans</h2>
                </div>

                <div class="content-paragraph">
                    <p>De första trehundra årens träsnitt graverades i längdträ, vilket innebar att gravören behövde skära genom träådringen åt olika håll. Det var inte möjligt att skära tunna, varandra närliggande eller korsande linjer. De tunna träpartierna skulle då omedelbart ha spruckit längs med ådringen. Gravören behövde alltså anpassa bildens komposition efter de förutsättningar som materialet gav. Det gjorde att linjerna blev lite grövre och konstvetenskapligt brukar man kalla det för träsnittsmaneret.
                        <br/><br/>
                        År 1775 lanserade britten Thomas Bewick metoden att utföra trägravyrer i ändträ, det vill säga i en stock som skurits tvärs över stammen och fibrerna. Den väl polerade hårda ytan av turkisk buxbom, järnek eller päronträ bearbetades med en gravstickel i stället för träsnidarnas skärstål. Härigenom kunde man uppnå betydligt finare linjespel och detaljåtergivning. Denna genomgripande förbättring gör att den nya typen av gravyrer för åtskillnadens skull brukar kallas för trägravyrer. En annan benämning är xylografi, efter grekiska <i>xylos</i> som betyder trä. Den nya metoden blev mycket populär och framgångsrik och var under 1800-talet den mest använda illustrationstekniken. Eftersom tekniken var förhållandevis billig jämfört med litografier och stålstick innebar detta en helt ny möjlighet att publicera rikt illustrerade böcker. En fördel var också att trägravyrerna kunde tryckas tillsammans med texten, medan kopparstick och litografier krävde att bild och text trycktes separat.
                    </p>
                </div>

                <div class="content-paragraph borderedImage">
                        <img src="@/assets/zebra.jpg" alt="Trägravyr föreställande en zebra från år 1799 av Thomas Bewick." />
                        <p>Trägravyr föreställande en zebra från år 1799 av Thomas Bewick.</p>
                 </div>

                 <div class="content-paragraph">
                        <h2>Trägravyrernas glansperiod</h2>
                    </div>

                <div class="content-paragraph">
                    <p>Xylografin stod på sin höjdpunkt – både konstnärligt och kommersiellt – under perioden 1840–1890. Under dessa år utgavs en mängd böcker och tidskrifter med xylografiska illustrationer. Bokförlag och tidningsredaktioner drev xylografiska ateljéer där tecknare och gravörer (xylografer) arbetade med framställandet av xylografierna. Graveringsarbetet lämpade sig dock även för frilansarbete i bostaden.
                     <br/><br/>
                     Bland de viktiga illustrerade tidningarna kan nämnas <i>The illustrated London news</i> och <i>The graphic</i> i Storbritannien, <i>L’illustration</i> i Frankrike och <i>Illustrirte Zeitung</i> i Tyskland. I Sverige fanns bland andra <i>Illustrerad tidning</i>, <i>Ny illustrerad tidning</i> och <i>Svenska familj-journalen</i>.
                    </p>
                </div>
                <div class="content-paragraph borderedImage">
                    <img src="@/assets/ny-illustrerad-tidning.jpg" alt="Ny illustrerad tidning gavs ut åren 1865–1900 och var Sveriges främsta illustrerade tidning. Här ses ett uppslag ur årgång 1866." />
                    <p><i>Ny illustrerad tidning</i> gavs ut åren 1865–1900 och var Sveriges främsta illustrerade tidning. Här ses ett uppslag ur årgång 1866.</p>
                </div>

                <div class="content-paragraph">
                    <h2>En svartvit värld</h2>
                </div>
                <div class="content-paragraph">
                    <p>Mångfaldigade bilder i böcker och tidningar var det normala sättet för folk att få kännedom om världen, bortsett från galleriutställningar av målningar eller fotografier. Detta var en tid då ytterst få hade förmånen att göra längre resor och upptäcka fjärran platser. Intresset för att ta del av geografiska bilder var därför stort – oavsett om det var Alingsås eller Taj Mahal.
                        <br/><br/>
                        De möjligheter som trägravyrerna bjöd ledde till att ett omfattande geografiskt dokumentationsprojekt tog sin början. Världen dokumenterades i bild och förmedlades till den tidningsläsande publiken i en tidigare ej skådad omfattning. Särskilt anlitade tecknare reste runt och tecknade städer, byggnader, naturvyer och vardagsscener hos lokalbefolkningen. Som exempel på svenska tecknare kan nämnas Carl Svante Hallbeck, Robert Haglund och Otto August Mankell, men de hade många kolleger. Många kända målarkonstnärer försörjde sig i sina tidiga år med illustrationsuppdrag för tidningar, exempelvis Carl Larsson och August Malmström. Det handlade dock inte bara om det egna landet, utan givetvis även om grannländer eller fjärran och exotiska länder. Den franska tidskriften <i>Le tour du monde</i> innehåller reseskildringar från världens alla hörn, rikt illustrerat med trägravyrer.
                    </p>
                </div>
                <div class="content-paragraph borderedImage">
                    <img src="@/assets/vom-kriegsschauplatz.jpg" alt="Den illustrerade tidningen Vom Kriegsschauplatz utkom en till tre gånger per vecka under fransk-tyska kriget 1870–1871, sammanlagt med sextio nummer à åtta sidor." />
                    <p>Den illustrerade tidningen <i>Vom Kriegsschauplatz</i> utkom en till tre gånger per vecka under fransk-tyska kriget 1870–1871, sammanlagt med sextio nummer à åtta sidor.</p>
                </div>
                <div class="content-paragraph">
                    <p>Tidens händelser i form av industriutställningar, kungakröningar, stadsbränder, järnvägsolyckor och fältslag dokumenterades av särskilt utsända tecknare. Exempelvis fransk-tyska kriget 1870–1871 skildrades i bild på plats av en rad korresponderande fältkonstnärer, varav några skickade sina teckningar med ballongpost från det belägrade Paris. Under detta krig gavs det också ut flera ”krigstidningar” i både Frankrike och Tyskland som i text och bild rapporterade från kriget.
                        <br/> <br/>
                        Franske konstnären Gustave Doré gjorde illustrationer till Bibeln, Dante Alighieris <i>Divina commedia</i> och en lång rad andra klassiska verk som publicerades som trägravyrer.
                    </p>
                </div>
                <div class="content-paragraph borderedImage">
                    <img src="@/assets/aarhus.jpg" alt="På friluftsmuseet Den Gamle By i Århus kan man se hur xylografens arbetsplats såg ut." />
                    <p>På friluftsmuseet Den Gamle By i Århus kan man se hur xylografens arbetsplats såg ut.</p>
                </div>
                
                <div class="content-paragraph">
                    <h2>Hur gick det till?</h2>
                </div>
                <div class="content-paragraph">
                    <p>Hur gick då graveringsarbetet till? Som nämnts tidigare användes ett trästycke (som kallades ”stock”) av ett hårt träslag, oftast buxbom, vid graveringen. (Buxbom är i norra Europa endast en buske, men i Medelhavsområdet når den storleken av ett träd.) Dessa block hyvlades och polerades sedan noggrant. Därefter hade en tecknare i uppgift att utifrån en förlaga (teckning eller fotografi) teckna motivet spegelvänt på stocken. Ibland överfördes bilder på kemisk väg till stocken. Vid graveringen använde xylografen en uppsättning av sticklar i olika utförande och storlek. Med dessa avlägsnade han trä där bilden skulle vara vit. Tänk på det när du betraktar en tryckt trägravyr: varje vitt fält har skapats genom att en motsvarande träpartikel har skurits bort! Xylografen lade ofta trästycket på en med sand fylld skinndyna, vilket gjorde det möjligt att lätt vända och vrida det åt olika håll. Teckningen på den ännu ej graverade ytan skyddades med papper, som revs bort vartefter arbetet fortskred. När xylografen skulle gravera finare detaljer använde han en lupp.
                        <br/><br/>
                        Om xylografen begick ett misstag var han tvungen att borra eller mejsla bort det felaktiga området och sätta in en ny träbit i stället. Stockens yta var förstås begränsad, med tanke på trädets begränsade omkrets. När man skulle gravera större bilder skruvade man därför ihop flera stockar till ett stort trästycke. På en tidningsredaktion där det kanske var bråttom att gravera en bild av en aktuell händelse kunde arbetet delas upp på flera xylografer och då var det chefsxylografen som ansvarade för att jämna ut graveringen vid skarvarna. Den färdiga stocken monterades tillsammans med texten till en komplett boksida. Den i bildstocken upphöjda bilden, liksom bokstavstyperna, färgades in med trycksvärta. En stock höll för åtminstone 100 000 avtryck, men ofta gjorde man en metallavgjutning i stället. Även om den på stocken upphöjda bilden nöttes i mycket liten utsträckning så gick nämligen själva stocken i sig lätt sönder, på grund av de upprätt stående träfibrerna.
                    </p>
                </div>

                <div class="content-paragraph borderedImage">
                    <img src="@/assets/wood-engraver.jpg" alt="Xylografens arbete krävde tålamod, koncentration och god syn." />
                    <p>Xylografens arbete krävde tålamod, koncentration och god syn.</p>
                </div>
                <div class="content-paragraph">
                    <p>Graveringen var ett mödosamt och tidskrävande arbete som krävde enorm koncentration. Det behövdes såväl en väl belyst och ljus arbetsplats som utmärkta ögon. Den som tar sig tiden att detaljstudera en trägravyr från 1800-talet kan få en liten aning om hur mycket tid som måste ha krävts för att snida dessa i vissa fall mikroskopiska detaljer i stocken. En väl utförd trägravyr krävde sin tid och därvid varierar förstås kvaliteten beroende på hur bråttom det var med publicering och vilken ambitionsnivå som beställaren hade.</p>
                </div>

                <div class="content-paragraph">
                    <h2>Tecknarna och xylograferna</h2>
                </div>
                <div class="content-paragraph">
                    <p>Många xylografier är märkta med signaturer – ibland hela namn och ibland initialer. Tempus Bildbyrå arbetar aktivt med att identifiera upphovspersonerna bakom signaturerna. (Om vi i något fall har misslyckats i våra efterforskningar är vi tacksamma för påpekande om detta.) Inskriften ”sc” är förkortning för latinets <i>sculpsit</i>, som betyder ”har graverat”. Denna förkortning – eller ”XA”, ”xylografisk anstalt” – brukar ledsaga xylografens signatur. Inskriften ”del” är förkortning för latinets <i>delineavit</i>, som betyder ”har tecknat” och brukar anges tillsammans med namnet på den som har tecknat förlagan till gravyren. Det kan vara flera tecknare inblandade i produktionen av en bild: en som tecknar något ”efter naturen” som det kallades, en som renritade och en som överförde teckningen till stocken innan xylografen skulle ta över.
                    <br/><br/>
                    Rutinen för angivande av signaturer varierade. Ibland angavs bara tecknare, ibland bara xylograf, ibland bägge – och ibland ingen alls. I ytterligare andra fall anges upphovspersoner i bildtexten och inte med signatur i bilden. Det var även vanligt att flera xylografer samarbetade – särskilt när det gällde större gravyrer. Det var då xylografiateljéns föreståndare som jämkade samman de olika delarna av gravyren och som graverade de mer utmanande delarna och det var också han som signerade gravyren. Xylograferna som deltog i detta lagarbete kunde ha olika specialiteter, exempelvis människor, hästar, arkitektur eller landskap.
                </p>
                </div>

                <div class="content-paragraph borderedImage">
                    <img src="@/assets/signatures.jpg" alt="Här är några av de mest kända svenska tecknarnas och xylografernas signaturer. Tecknarna är Robert Haglund (1844–1930), Carl Svante Hallbeck (1826–1897), Albert Theodor Gellerstedt (1836–1914) och Otto August Mankell (1838–1885). Xylograferna är Edward Skill (1831–1873), Ida Falander (1842–1927), Wilhelm Meyer (1844–1944) och Evald Hansen (1840–1921). Skill var en brittisk xylograf som var chef för Ny illustrerad tidnings xylografiska ateljé åren 1864–1870. Meyers xylografiska ateljé var i slutet av 1800-talet den ledande i Sverige." />
                    <p>Här är några av de mest kända svenska tecknarnas och xylografernas signaturer. Tecknarna är Robert Haglund (1844–1930), Carl Svante Hallbeck (1826–1897), Albert Theodor Gellerstedt (1836–1914) och Otto August Mankell (1838–1885). Xylograferna är Edward Skill (1831–1873), Ida Falander (1842–1927), Wilhelm Meyer (1844–1944) och Evald Hansen (1840–1921). Skill var en brittisk xylograf som var chef för <i>Ny illustrerad tidnings</i> xylografiska ateljé åren 1864–1870. Meyers xylografiska ateljé var i slutet av 1800-talet den ledande i Sverige.</p>
                </div>

                <div class="content-paragraph">
                    <p>I xylografiska ateljéer signerades xylografierna ofta med föreståndarens namn, oavsett om denne medverkat i arbetet eller inte. En större gravyr var dessutom ofta resultatet av flera personers arbete. Var och en erhöll då ett mindre träblock att gravera och sedan monterades de ihop som ett pussel. Bakom signaturer från ateljéer döljer sig därför ofta flera xylografer – för eftervärlden i praktiken okända, eftersom arbetsfördelningen vid varje enskilt gravyruppdrag inte dokumenterats.
                        <br/><br/>
                        Till de mer namnkunniga svenska manliga xylograferna hör Hans Peter Hansen, Evald Hansen, Gunnar Forssell, Jonas Engberg och Wilhelm Meyer, varav den siste drev Sveriges ledande xylografiska anstalt. 1800-talet var en tid då vissa yrken var formellt eller i praktiken stängda för kvinnor, men xylografyrket var ett yrke där även kvinnor var verksamma. Bland svenska kvinnliga xylografer märks Carolina Weidenhayn och Ida Falander. Den sistnämnda var föreståndare för <i>Ny illustrerad tidnings</i> xylografiska ateljé åren 1874–1877 och var särskilt skicklig på att gravera porträtt. Just porträttgravering krävde ett särskilt skickligt handlag, för att levandegöra den porträtterades ansiktsdrag.
                    </p>
                </div>

                <div class="content-paragraph">
                     <h2>Ny tid med ny teknik</h2>
                </div>

                <div class="content-paragraph">
                    <p>På 1880-talet började man testa att reproducera bilder genom att på fotomekanisk väg skapa rasterklichéer i stället för xylografier. Den nya tekniken utvecklades och under 1890-talet trängdes xylografierna successivt undan. I början var resultatet inte alltid så elegant med hänsyn till skärpa och kontrast och rastreringen kunde vara mycket påtaglig. Snabbheten och den ekonomiska besparingen gjorde emellertid valet lätt för de flesta publicister. Ett undantag är produktkataloger där trägravyrer just med anledning av sin tydlighet och detaljrikedom förekom ända fram till på 1960-talet. En viss form av trägravyr kom också att leva vidare, men som en ren konstform och i princip aldrig för dokumentära bokillustrationer.</p>
                </div>
                <div class="content-paragraph borderedImage">
                    <img src="@/assets/detail.jpg" alt="Trägravyrerna utvecklades till en egen estetisk egenart och är i dag omtyckta bland annat för sin detaljrikedom och för sina klara kontraster." />
                    <p>Trägravyrerna utvecklades till en egen estetisk egenart och är i dag omtyckta bland annat för sin detaljrikedom och för sina klara kontraster.</p>
                </div>

                <div class="content-paragraph">
                    <h2>Några bildtekniska spörsmål</h2>
                </div>
                <div class="content-paragraph">
                    <p>Bilderna tillhandahålls i gråskaleläge i en upplösning av minst 600 ppi i TIFF-format. Bilderna är digitalt rengjorda versioner av originalbilderna som oftast har flammig pappersbakgrund med fläckar. Papper åldras olika beroende på kvaliteten. Du som är skicklig på bildbehandling vill kanske ha originalfilen för att kunna göra den bearbetning som ger bäst resultat för just ditt ändamål. Det är oundvikligt att bearbetning av tusentals bildfiler blir mer rutinartad än om man bearbetar ett fåtal bildfiler för ett särskilt ändamål.
                        <br/><br/>
                        Graverade bilder saknar rastrering, eftersom de trycktes genom högtryck och inte på fotomekanisk väg. Liksom när en bild är rastrerad kan dock linjerna i en gravyr ge upphov till <a href="https://en.wikipedia.org/wiki/Moir%C3%A9_pattern" target="_blank">moiréeffekt</a> när du trycker den och vid visning i olika storlek på bildskärm. Det finns olika sätt att bearbeta bilden i bildbearbetningsprogram för att undvika moiréeffekten. Under mitt mångåriga samarbete med duktiga grafiker har jag konstaterat att moiréeffekten alltid kan undvikas. Enligt köpvillkoren är det ditt ansvar som kund att göra sådana tekniska bearbetningar av bildfilen att tillfredsställande kvalitet uppnås vid publicering.
                        <br/><br/>
                        Om du skall publicera en bild på Internet måste du minska upplösningen till 72 ppi. Det kan dock vara en fördel att göra eventuell bearbetning i högre upplösning och först därefter minska upplösningen. Notera att du inte får publicera bilden i hög upplösning på Internet, eftersom någon annan då lätt kan kopiera den.
                    </p>
                </div>

                <div class="content-paragraph">
                     <h2>Vidare läsning</h2>
                </div>
                <div class="content-paragraph">
                    <p>För den som vill läsa vidare om trägravyrer rekommenderas Lena Johannessons avhandling <i>Xylografi och pressbild. Bidrag till trägravyrens och till den svenska bildjournalistikens historia</i> (Almqvist och Wiksell, Stockholm 1982).</p>
                </div>

            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.illustration-info-container {
    text-align: left;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.05);

    .illustration-info {
        margin: 0 25%;
        // height: 100vh;
        background-color: #fff;

        @media only screen and (max-width: 992px) {
            margin: 0 20px;
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: 600px) {
            width: auto;
        }

        // @media screen and (max-width: $bp-mobile) {
        //   width: 100%;
        //   margin: 0;
        // }

        .header {
            span {
                margin: 0 1em;
                cursor: pointer;
            }

            h3 {
                padding: 1em;
            }
        }

        .content {
            padding: 1em;

            h1 {
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;
            }

            h2 {
                font-size: 22px;
                font-weight: 500;
                line-height: 36px;
            }

            .content-paragraph {
                font-size: 16px;
                font-weight: 500;
                line-height: 29px;

                ul {
                    list-style-type: disc;
                    padding-left: 20px;
                }

                a {
                    text-decoration: underline;
                    color: #1c1c68;
                }

                .borderedImage{
                border: 1px solid gray;
                text-align: center;
                font-size: 14px;
                width: 45%;
                height: fit-content;

                    @media only screen and (max-width: 600px) {
                        width: 100%;
                    }
                }
            }

            .borderedImage{
                border: 1px solid gray;
                text-align: center;
                font-size: 14px;
            }

            .split-content{
                display: flex;
                flex-direction: row;
                gap: 30px;

                .leftSide{
                    width: 55%;

                     @media only screen and (max-width: 600px) {
                    width: 100%;
                }
                }

                // img{
                //     width: 40%;
                // }

                @media only screen and (max-width: 992px){
                    flex-direction: column;

                    img{
                        width: 100%;
                    }
                }

                
            }

            div {
                padding: 1em;
            }

            .about {
                p {
                    margin: 0;
                    font-size: 0.9em;
                    line-height: 1.4em;
                }
            }

            .category {
                div {
                    padding: 0;
                    margin: 1em 0;

                    &:last-of-type {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            .description {
                background-color: #dfe6e9;
                padding-left: 0;
                padding-right: 0;
            }

            .tags {
                display: block;

                .badge {
                    display: inline-block;
                    font-size: 0.75em;
                    padding: 0.5em 0.75em;
                    border-radius: 5px;
                    color: #fff;
                    margin: 0 0.5em 0.5em 0;
                    background-color: #1c1c68;
                }
            }
        }
    }


}</style>
